

* {
  box-sizing: border-box;
}



.container {
  margin: 0.5rem;
  text-align: center;
  color: white;
}

.aboutContent {
    padding: 1rem 1rem 1rem 1rem;
    font-weight: normal;
}

.rulesContent {
  padding: 1rem 2rem 1rem 2rem;
  font-weight: normal;
}

.pageContent {
  padding: 1rem 1rem 1rem 1rem;
  font-weight: normal;
  font-size: 1rem;
}

.rulesContentInList {
  padding: 1rem;
  font-weight: normal;
  font-size: 1rem;
}

h1 {
  font-size: 4rem;
}

ol {
  list-style-position: inside;
  font-weight: bold;
  font-size: 1.5rem;
}

ul {
  list-style-position: inside;
  font-weight: bold;
  font-size: 1.2rem;
}

.App {
  text-align: center;
  background-color: #282c34;
}

body {
  background-color: #282c34;
  margin: 0;
}

.PageContent {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.nav {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 2rem;
}

.site-title {
  font-size: 2rem;
  align-items: center;
}

.logoInPage {
  height: 20vmin;
  align-items: center;
  pointer-events: none;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 2rem;
  font-size: 1.7rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: #555;
}

.nav li:hover {
  background-color: #777
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
